// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { LogLevel } from '@wellro/utils';

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAeyUrVWNWRe8LVIljY4j7PlmJDo-wWdNg',
    authDomain: 'wellro-dev.firebaseapp.com',
    projectId: 'wellro-dev',
    storageBucket: 'wellro-dev.appspot.com',
    messagingSenderId: '149375594051',
    appId: '1:149375594051:web:48c476071738ecd5556ef6',
    measurementId: 'G-95D21N7PST',
  },
  sentry: {
    dsn:
      'https://70510fe5dcd2445bb5ea176c5d805032@o4504682974740480.ingest.sentry.io/4504693742239744',
    env: 'development',
    replaysSessionSampleRate: 0.1,
  },
  tenantId: 'doctor-n2ya0',
  logLevel: LogLevel.DEBUG,
  hostUrl: 'https://dev-doctor.wellro.life',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
